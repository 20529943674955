/* src/CustomCalendar.css */
.custom-calendar {
    /* max-width: 300px; */
    margin: 0 auto;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
 
    background-color:rgb(255 255 255 / 10%);
    color: #fff;
  }
  .calendar-header-left{    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;}
  
  .calendar-header button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    color: #ccc;
    font-weight: 400;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    /* padding: 10px; */
  }
  
  .calendar-day {
    padding: 5px;
    width: 100%;
    min-height: 170px;
    background: rgb(255 255 255 / 10%);
    border: 1px solid rgb(0 0 0 / 20%);
}
.calendar-day .btn_man{ border: 1px solid #fff;}
  
  .calendar-day.selected {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
    font-weight: bold;
  }
  .h2{
    color: black;
  }
  .week{
    background: rgb(0 0 0 / 40%);
    padding: 15px;
    margin-top: 40px;
  }
  .calendar-week strong{
    font-weight: 700;
    font-size: 18px;
  }
  .calendar-day strong{
    font-weight: 700;
    font-size: 18px;
  }
  .calendar-header h2{
    font-weight: 400;
    font-size: 20px;
  }

  .list-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.list-menu .form-select{    padding: 10px;
    background: transparent;
    color: #fff;outline: none;
    border: 0;}
.list-menu .form-select option{padding: 10px; background:#222231; border: 0;} 
.list-menu .form-select option:hover{ background:#25263f; } 
.list-menu > li {
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  position: relative;
}
.list-menu li a {
  display: block;
  padding: 20px 28px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
}
.list-menu .sub-menu {
  position: absolute;
  z-index: 10;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-5%);
  transform: translateY(-5%);
  top: 100%;
  left: 0;
  width: 100%;
  background: #222231;
}
.list-menu .sub-menu li {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}
.list-menu .sub-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
}
.list-menu .sub-menu li a img {
  max-width: 20px;
}
.list-menu li:hover .sub-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}
.list-menu li a.toggler::before {
  content: "\f078";
  position: absolute;
  top: 50%;
  right: -28px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.7);
  font-family: "icomoon";
  font-size: 8px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font: normal normal normal 14px/1 FontAwesome;
}
.calendar-header button {
  position: relative;
  top: 0;
  width: 59px;
  height: 59px;
  border-radius: 0%;
  background-color: transparent;
  border: 1px solid #2E2F3C;
  color: #FFFFFF;
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.calendar-header button i {
  font-size: 16px;
  color: #B5B5BA;
  font: normal normal normal 14px/1 FontAwesome;
}
body .css-13cymwt-control, body .css-t3ipsp-control{     box-shadow: none;   background: transparent!important;  border: none!important;padding: 10px;}
body .css-1nmdiq5-menu{background:#222231!important}
body .css-1u9des2-indicatorSeparator { display: none!important;}
.css-b62m3t-container {  width: 200px;}
.css-1fdsijx-ValueContainer{    text-align: left;}
body .css-1dimb5e-singleValue{color:#fff;}
body .css-1nmdiq5-menu{ text-align: left;}
body .css-1nmdiq5-menu img{width: 20px!important;    margin-right: 10px;}

@media (max-width: 768px){
.calendar-header {  position: initial;}
.calendar-header button { width: 40px;height: 40px;}
.calendar-header button:last-child{    margin-left: auto; }
.calendar-header { display: block;}
.list-menu > li{  width: 50%;}
.css-b62m3t-container {
  width: 100%;
}
.calendar-day { padding: 3px; min-height: 90px;    font-size: 10px;}
.week {margin-top: 15px;}
.calendar-header h2{    margin: auto;}
.week .calendar-week h6{font-size: 0.8rem;}

.calendar-day .btn_man {
    padding: 1px 4px;
    border-radius: 8px;
    margin: 10px auto 5px;width: 100%;
    font-size: 12px;    white-space: nowrap;
}
.calander_main{    overflow: auto;}
.calander_main .calendar-grid{    width: 600px;}
}